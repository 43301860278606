import * as React from "react"
import PropTypes from "prop-types"

import { StaticImage } from "gatsby-plugin-image"
import  FaFacebookF  from "../images/faFacebookF";
import  FaInstagram  from "../images/FaInstagram";
import  FaTwitter  from "../images/FaTwitter";
import "./../css/styles.scss";
import "./../css/header.scss";
import { Link } from "gatsby"


const MainLogo = "../images/Inland-Tours-Main-Logo.png";

const DetailsHeader = () => {
  return (
    <header id="header">
<div className="header-fixed">
  <div className="header-content">
   <div className="row">
    <div className="col-sm-4 first">
      <ul className="contact">
        <li>
          <a href="tel:5016156239">
            Tel: +(501) 615-6239
          </a>
        </li>
        <li>
          <a href="">
          Email: bz.inlandtours@gmail.com
          </a>
        </li>
      </ul>
    </div>
    <div className="col-sm-4 middle">
         <a href="">
              #1 on Trip Advisor
          </a>
    </div>
    <div className="col-sm-4 last">
      <ul className="social">
        <li>
          <a href="">
              <FaFacebookF />
          </a>
        </li>
        <li>
          <a href="">
              <FaInstagram />
          </a>
        </li>
        <li>
          <a href="">
              <FaTwitter />
          </a>
        </li>
      </ul>
    </div>
   </div>
  </div>
</div>

    </header>
  )

}



export default DetailsHeader


