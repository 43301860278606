import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/details-layout"
import Seo from "../../components/seo"
import * as styles from "../../css/styles.scss"
import "./accommodations.scss"

import Accommodation from "./components/accomodation"

const Accommodations = () => (
  <Layout>
    <Seo title="Belize Inland Tours | Belize Inland Tours Accommodations " />
    <Accommodation/>
  </Layout>
)

export default Accommodations;
