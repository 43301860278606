import * as React from "react"
import PropTypes from "prop-types"
import "./../css/styles.scss";
import 'bootstrap/dist/css/bootstrap.css';
import DetailsHeader from "./details-header";
import Footer from "./footer";
import { useStaticQuery, graphql } from "gatsby"




const DetailsLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
        <div className="App">
        <DetailsHeader />
            <div className="content">
                <div>
                  
                    <main>{children}</main>
                </div>
            </div>
            <Footer />
            
        </div>
    </>
);
}

DetailsLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DetailsLayout
