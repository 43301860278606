import * as React from "react"

function FaBack() {

	return (

        
        

<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="25.000000pt" height="25.000000pt" viewBox="0 0 25.000000 25.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,25.000000) scale(0.100000,-0.100000)"
fill="#FFF7E1" stroke="none">
<path d="M70 165 l-34 -35 34 -35 c19 -19 38 -35 44 -35 5 0 -3 14 -19 30
l-29 30 62 0 c35 0 62 4 62 10 0 6 -27 10 -62 10 l-62 0 29 30 c16 17 24 30
19 30 -6 0 -25 -16 -44 -35z"/>
</g>
</svg>

 

    );
}

export default FaBack;